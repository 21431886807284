<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
        :pageSettings="pageSettings"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="reference"
            headerText="Int. Nr."
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="customgroup"
            headerText="Gruppe"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="company"
            headerText="Firma"
            textAlign="Left"
          ></e-column>
          <e-column
            field="first_name"
            headerText="Vorname"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="last_name"
            headerText="Nachname"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="full_name"
            headerText="Ansprechpartner"
            textAlign="Left"
          ></e-column>
          <e-column
            field="memo"
            headerText="<i class='fas fa-comment' data-toggle='tooltip' data-placement='top' title='Kommentar'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="invited"
            headerText="<i class='fas fa-envelope' data-toggle='tooltip' data-placement='top' title='Einladung versendet'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="72"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Acquisition -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title v-if="modalEdit">Aussteller bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Aussteller hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-3" style="border-right: 1px solid #aaa;">
              <legend><mdb-icon icon="user" class="red-text" /> Ansprechpartner</legend>
              <input type="hidden" v-model="model.id">
              <mdb-input
                label="Firma"
                type="text"
                class="mb-3 mt-3"
                v-model="model.company"
                required
              />
              <mdb-select
                label="Anrede"
                class="mt-3 mb-0"
                v-model="model.title"
                ref="title"
                validation
              />
              <mdb-input
                label="Titel"
                type="text"
                class="mt-0 mb-3"
                v-model="model.title_honorific"
              />
              <mdb-input
                label="Vorname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.first_name"
                required
              />
              <mdb-input
                label="Nachname"
                type="text"
                class="mb-3 mt-3"
                v-model="model.last_name"
                required
              />
              <legend><mdb-icon icon="map-marked-alt" class="red-text mt-4" /> Anschrift</legend>
              <mdb-input
                label="Straße"
                type="text"
                class="mb-3 mt-3"
                v-model="model.street"
              />
              <mdb-input
                label="PLZ"
                type="text"
                class="mb-3 mt-3"
                v-model="model.zip"
              />
              <mdb-input
                label="Stadt"
                type="text"
                class="mb-3 mt-3"
                v-model="model.city"
              />
              <mdb-input
                label="Region"
                type="text"
                class="mb-3 mt-3"
                v-model="model.region"
              />
              <mdb-input
                label="Land"
                type="text"
                class="mb-3 mt-3"
                v-model="model.country"
              />
            </mdb-col>
            <mdb-col md="6" class="mb-3">
              <legend><mdb-icon icon="phone" class="red-text" /> Kontakt</legend>
              <mdb-input
                label="Telefon"
                type="text"
                class="mb-3 mt-3"
                v-model="model.phone"
              />
              <mdb-input
                label="Mobil"
                type="text"
                class="mb-3 mt-3"
                v-model="model.mobile"
              />
              <mdb-input
                label="Fax"
                type="text"
                class="mb-3 mt-3"
                v-model="model.fax"
              />
              <mdb-input
                label="E-Mail"
                type="text"
                class="mb-3 mt-3"
                v-model="model.email"
                required
              />
              <legend><mdb-icon icon="cog" class="red-text mt-4" /> Sonstiges</legend>
              <!--<mdb-input
                label="Int. Nr."
                type="text"
                class="mb-3"
                v-model="model.reference"
              />-->
              <mdb-select
                label="Gruppe"
                class="my-0 mt-3 mb-0"
                v-model="model.customgroup"
                ref="customgroup"
                validation
                search
              />
              <legend><mdb-icon icon="comment" class="red-text mt-4" /> Anmerkung</legend>
              <vue-editor
                id="memoEditor"
                v-model="model.memo"
                :editor-toolbar="editorToolbar"
              />
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="red" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" v-if="modalEdit">Speichern</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" v-else>Hinzufügen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Acquisition -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Aussteller löschen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Aussteller wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="deleteRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: Segment -->
    <mdb-modal :show="showSegment" @close="showSegment = false">
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Gruppe zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Aussteller mit folgender Gruppe verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Gruppe"
                    class="mt-3"
                    v-model="model.customgroup"
                    ref="bulk_segment"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.full_name">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showSegment = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="segmentEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Segment -->
    <!-- Modal: inviteRecords -->
    <mdb-modal
      size="lg"
      scrollable
      removeBackdrop
      :show="showInviteRecords"
      @close="showInviteRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Einladung senden</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="envelope" far size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Gruppe an Ausstellern aus, die eine Einladung per E-Mail erhalten sollen:
            </p>
            <mdb-input type="radio" id="invite-option-1" name="inviteOptions" radioValue="selection" v-model="activeRecords.scope" :disabled="selectedRecords.length === 0" class="mb-2" label="Ihre Auswahl" />
            <mdb-input type="radio" id="invite-option-2" name="inviteOptions" radioValue="pending_acquisition" v-model="activeRecords.scope" class="mb-2" label="Alle Aussteller, die noch keine Einladung erhalten haben" />
            <hr>
            <p><b>Hinweis:</b> Bitte vor dem Versand des Reminders die entsprechenden Texte unter "Einstellungen" > "Reminderversand" einpflegen.</p>
            <mdb-input type="radio" id="invite-option-3" name="inviteOptions" radioValue="selection_acquisition_reminder" v-model="activeRecords.scope" :disabled="selectedRecords.length === 0" class="mb-2" label="Reminder: Ihre Auswahl" />
            <mdb-input type="radio" id="invite-option-4" name="inviteOptions" radioValue="pending_acquisition_reminder" v-model="activeRecords.scope" class="mb-2" label="Reminder: Alle eingeladenen Aussteller ohne Gruppe 'Anmeldung liegt vor'" />
            <mdb-card class="mt-4" v-show="activeRecords.scope === 'selection' || activeRecords.scope === 'selection_acquisition_reminder'">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Aussteller:</div>
                  <ul class="mb-0">
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.id">
                      {{ record.full_name }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title class="text-muted">Warteschlange</mdb-card-title>
                <mdb-card-text>
                  <mdb-progress :value="activeRecordsProgress" :height="20" />
                  <p>{{ activeRecords.processed }} von {{ activeRecords.total }} Einladungen versendet</p>
                  <p v-show="activeRecords.complete === true" class="green-text">Versand abgeschlossen, Sie können den Dialog jetzt schließen</p>
                  <p v-show="activeRecords.failed.length === 1" class="red-text">{{ activeRecords.failed.length }} Einladung konnte nicht versendet werden</p>
                  <p v-show="activeRecords.failed.length > 1" class="red-text">{{ activeRecords.failed.length }} Einladungen konnten nicht versendet werden</p>
                  <ul v-show="activeRecords.failed.length > 0" class="red-text">
                    <li v-for="failed in activeRecords.failed" :key="failed.id">
                      E-Mail: {{ failed.label }}
                    </li>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showInviteRecords = false">Schließen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true || activeRecords.total === 0 || activeRecords.complete === true" @click.native="processRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Senden
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: inviteRecords -->
    <!-- Modal: importRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportRecords"
      @close="showImportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Ausstellerdaten importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Excel-Datei aus die importiert werden soll:
            </p>
            <input type="file" @change="handleFileUpload( $event )"/>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showImportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @shown="exportRecordsShown"
      @close="showExportRecords"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Ausstellerdaten exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="red-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Gruppe an Ausstellern aus, deren Daten in eine Excel-Datei exportiert werden soll:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="confirmed" v-model="action.export" class="mb-2" label="Zusagen" />
            <mdb-input type="radio" id="export-option-2" name="exportOptions" radioValue="cancelled" v-model="action.export" class="mb-2" label="Absagen" />
            <mdb-input type="radio" id="export-option-8" name="exportOptions" radioValue="failed" v-model="action.export" class="mb-2" label="Fehlgeschlagen" />
            <mdb-input type="radio" id="export-option-4" name="exportOptions" radioValue="deleted" v-model="action.export" class="mb-2" label="Gelöschte nach DSGVO" />
            <mdb-input type="radio" id="export-option-6" name="exportOptions" radioValue="pending" v-model="action.export" class="mb-2" label="Ohne Rückmeldung" />
            <mdb-input type="radio" id="export-option-3" name="exportOptions" radioValue="visited" v-model="action.export" class="mb-2" label="Erschienen" />
            <mdb-input type="radio" id="export-option-7" name="exportOptions" radioValue="absent" v-model="action.export" class="mb-2" label="Abwesend" />
            <mdb-input type="radio" id="export-option-5" name="exportOptions" radioValue="all" v-model="action.export" class="mb-2" label="Alle Aussteller" />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Aussteller:</div>
                  <p v-if="this.action.export === 'confirmed'" class="pl-4">{{ exportRecordCount }} Aussteller mit Zusage</p>
                  <p v-if="this.action.export === 'cancelled'" class="pl-4">{{ exportRecordCount }} Aussteller mit Absage</p>
                  <p v-if="this.action.export === 'failed'" class="pl-4">{{ exportRecordCount }} fehlgeschlagene E-Mail Einladungen</p>
                  <p v-if="this.action.export === 'deleted'" class="pl-4">{{ exportRecordCount }} Aussteller mit Aufforderung zur Löschung</p>
                  <p v-if="this.action.export === 'pending'" class="pl-4">{{ exportRecordCount }} Aussteller ohne Rückmeldung</p>
                  <p v-if="this.action.export === 'visited'" class="pl-4">{{ exportRecordCount }} Aussteller zur Veranstaltung erschienen</p>
                  <p v-if="this.action.export === 'absent'" class="pl-4">{{ exportRecordCount }} Aussteller nicht erschienen</p>
                  <p v-if="this.action.export === 'all'" class="pl-4">{{ exportRecordCount }} Aussteller</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Exportieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit, Filter, Group, VirtualScroll, InfiniteScroll } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect,
  mdbProgress
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Acquisition',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    mdbProgress,
    VueEditor
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        timeslot: '',
        consultant: '',
        notification: '',
        reference: '',
        segment: '',
        customgroup: [],
        memo: '',
        company: '',
        title: [
          { text: 'Herr', value: 'Herr', selected: true },
          { text: 'Frau', value: 'Frau', selected: false }
        ],
        title_honorific: '',
        first_name: '',
        last_name: '',
        street: '',
        zip: '',
        city: '',
        region: '',
        country: '',
        phone: '',
        mobile: '',
        fax: '',
        email: ''
      },
      selectedRecords: [],
      activeRecords: {
        endpoint: '',
        selection: [],
        selected: 0,
        model: 'participants',
        scope: 'all',
        target: 'acquisition',
        total: 0,
        processed: 0,
        failed: [],
        complete: false
      },
      action: {
        selection: [],
        eventCategoryOptions: [],
        eventYearOptions: [],
        eventLocationOptions: [],
        export: 'confirmed'
      },
      eventCategory: localStorage.getItem('eventCategory'),
      eventYear: localStorage.getItem('eventYear'),
      event: localStorage.getItem('event'),
      username: localStorage.getItem('username'),
      file: '',
      exportRecordCount: 0,
      showModal: false,
      modalEdit: false,
      showDelete: false,
      showSegment: false,
      showInviteRecords: false,
      showImportRecords: false,
      showExportRecords: false,
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_acquisitions.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      pageSettings: {
        pageSize: 300
      },
      infiniteScrollSettings: {
        enableCache: true,
        initialBlocks: 3,
        maxBlocks: 3
      },
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Einen Aussteller hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Einen Aussteller bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Einen oder mehrere Aussteller löschen', prefixIcon: 'e-delete', id: 'deleteRecord' },
        { type: 'Separator' },
        { text: 'Gruppe', tooltipText: 'Ausstellern eine Gruppe zuweisen', prefixIcon: 'e-icon-bookmark', id: 'segmentRecord' },
        { type: 'Separator' },
        { text: 'Einladung', tooltipText: 'Einladungen an Aussteller versenden', prefixIcon: 'e-icon-chevron-right-double', id: 'inviteRecords' },
        /*{ type: 'Separator' },*/
        /*{ text: 'Import', tooltipText: 'Aussteller importieren', prefixIcon: 'e-icon-upload', id: 'importRecords' },*/
        /*{ text: 'Export', tooltipText: 'Ausstellerdaten exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },*/
        'Search'
      ],
      // { text: 'Außendienst', tooltipText: 'Ausstellern einen Außendienst-Mitarbeiter zuweisen', prefixIcon: 'e-icon-resource', id: 'consultRecord' }, { type: 'Separator' },
      chipTemplate: function () {
        return { template: Vue.component('columnTemplate', {
          template: '<span class="badge red m-1">segment</span>',
          data: function() {
            return {
              data: {}
            }
          },
          computed: {
            segment: function() {
              return this.data.segment
            }
          }
        })}
      },
      filterOptions: {
        type: 'Menu'
      },
      filter: {
        type: 'CheckBox'
      },
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      sortSettings: {
        columns: [
          {
            field: 'company',
            direction: 'Ascending'
          }
        ]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
  },
  computed: {
    exportAction() {
      return this.action.export;
    },
    activeRecordsChange() {
      return this.activeRecords.scope;
    },
    activeRecordsProgress() {
      if (this.activeRecords.total !== 0) {
        return (this.activeRecords.processed / this.activeRecords.total) * 100
      } else {
        return 0
      }
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['segmentRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    },
    activeRecordsChange: {
      handler: function () {
        this.setActiveRecords(this.activeRecords.scope)
      },
      deep: true
    },
    exportAction: {
      handler: function () {
        axios.get('get_export_count.php?action=' + this.action.export).then(response => {
          this.exportRecordCount = response.data
        })
      }
    }
  },
  methods: {
    eventChange: function () {
      this.$refs.grid.refresh()
    },
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['reference', 'segment', 'customgroup'])
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
        //console.log(this.groupSettings.columns)
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'invited') {
        if (args.data['invited'] === 1) {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['invited'] === 2) {
          args.cell.classList.add('status-circle', 'status-green')
        } else if (args.data['invited'] === 3) {
          args.cell.classList.add('status-circle', 'status-blue')
        } else if (args.data['invited'] === 4) {
          args.cell.classList.add('status-circle', 'status-yellow')
        } else if (args.data['invited'] === 5) {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        if (this.selectedRecords.length > 0) {
          axios.get('get_acquisition.php?id=' + this.selectedRecords[0].id).then(response => {
            //this.model = response.data
            this.model.company = response.data.company
            this.model.street = response.data.street
            this.model.zip = response.data.zip
            this.model.city = response.data.city
            this.model.region = response.data.region
            this.model.country = response.data.country
            this.model.segment = response.data.segment
            this.model.customgroup = response.data.customgroup
            this.showModal = true
            this.modalEdit = false
          })
        } else {
          this.showModal = true
          this.modalEdit = false
        }
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_acquisition.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.action.selection = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'segmentRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showSegment = true
      }
      if (args.item.id === 'inviteRecords') {
        this.activeRecords.endpoint = 'send_acquisitions'
        if (this.selectedRecords.length === 0) {
          this.activeRecords.scope = 'pending_acquisition'
          this.setActiveRecords('pending_acquisition')
        } else {
          this.activeRecords.scope = 'selection'
          this.setActiveRecords('selection')
        }
        this.showInviteRecords = true
      }
      if (args.item.id === 'importRecords') {
        this.showImportRecords = true
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_acquisition.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    resetActiveRecords: function() {
      this.activeRecords.selection = []
      this.activeRecords.selected = 0
      this.activeRecords.total = 0
      this.activeRecords.processed = 0
      this.activeRecords.failed = []
      this.activeRecords.complete = false
    },
    setActiveRecords: function(scope) {
      this.resetActiveRecords()
      if (this.activeRecords.scope === 'selection' || this.activeRecords.scope === 'selection_acquisition_reminder') {
        for (const record of this.selectedRecords){
          this.activeRecords.selection.push(record.id)
        }
        this.activeRecords.total = this.activeRecords.selection.length
      } else {
        axios.get('count_records.php?model=' + this.activeRecords.model + '&scope=' + scope + '&target=' + this.activeRecords.target).then(response => {
          this.activeRecords.selection = response.data.selection
          this.activeRecords.total = response.data.total
        })
      }
    },
    closeModal () {
      this.showModal = false
      this.resetModel()
      //this.$refs.grid.clearRowSelection()
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.company !== '' && this.model.title !== '' && this.model.first_name !== '' && this.model.last_name !== '' && this.model.email !== '') {
        this.loading = true
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_acquisition.php'
        } else {
          url = 'add_acquisition.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
            //this.$refs.grid.clearRowSelection()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteRecord () {
      this.loading = true
      var url = 'delete_acquisition.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    segmentEntry () {
      this.loading = true
      var url = 'segment_acquisition.php'
      const payload = {
        ids: this.action.selection,
        segment: this.model.customgroup
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showSegment = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecordsShown () {
      axios.get('get_export_count.php?action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
    },
    async processRecords () {
      this.loading = true
      for (const record of this.activeRecords.selection) {
        this.activeRecords.selected = record
        await this.queryRecord()
      }
      this.activeRecords.complete = true
      this.loading = false
      this.$refs.grid.refresh()
      this.$refs.grid.clearRowSelection()
    },
    async queryRecord () {
      await axios({
        method: 'post',
        url: this.activeRecords.endpoint + '.php',
        data: this.activeRecords
      }).then(response => {
        if (response.data.success === false) {
          this.activeRecords.failed.push(response.data.failed)
          this.activeRecords.processed++
        } else {
          this.activeRecords.processed++
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    handleFileUpload( event ) {
      this.file = event.target.files[0]
    },
    importRecords () {
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post( 'import_acquisitions.php',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showImportRecords = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_acquisitions.php?action=' + this.action.export
      window.open(url)
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.timeslot = []
      axios.get('list_timeslots.php').then(response => {
        this.model.timeslot = response.data
      })
      this.model.consultant = []
      axios.get('list_consultants.php').then(response => {
        this.model.consultant = response.data
      })
      axios.get('get_export_count.php?action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
      this.model.notification = ''
      this.model.reference = ''
      this.model.segment = ''
      axios.get('list_segments.php').then(response => {
        this.model.customgroup = response.data
      })
      this.model.customgroup = []
      this.model.memo = ''
      this.model.company = ''
      this.model.title = [
        { text: 'Herr', value: 'Herr', selected: true },
        { text: 'Frau', value: 'Frau', selected: false }
      ]
      this.model.title_honorific = ''
      this.model.first_name = ''
      this.model.last_name = ''
      this.model.street = ''
      this.model.zip = ''
      this.model.city = ''
      this.model.region = ''
      this.model.country = ''
      this.model.phone = ''
      this.model.mobile = ''
      this.model.fax = ''
      this.model.email = ''
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit, Filter, Group, VirtualScroll, InfiniteScroll]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  switch (value.field) {
    case 'test':
      return value.key + ' ' + x
    default:
      // return value.field + ': ' + value.key + ' ' + x
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

main {
  padding-top: 13px !important;
}

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

#memoEditor {
  height: 100px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

.e-search-icon {
  font-size: 20px !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 295px !important;
}

.e-search input {
  font-size: 17px !important;
}
</style>
